/*global ga, History, Modernizr, FastClick, alert*/

window.main 			=	window.main || {};

var htmlbody			=	$('html,body');
var body				=	$('body');

var ww 					=	$(window).width();
var wh 					=	$(window).height();

var gridToRandomise 	=	$('.u-grid--random');
var itemToRandomise 	=	$('.u-float--random');
var randomNumber 		= 	0;

var menu 				= 	$('.c-menu');
var btnToggleMenu 		=	$('.js-toggle-menu');
var navLink 			=	$('.js-nav__link');
var itemToShowInGallery 	= 	$('.js-show-in-gallery');
var galleryImageContainer 	=	$('.c_gallery__image-container');
var galleryBtnClose 		=	$('.c_gallery__btn--close');
var galleryBtnPrev 			=	$('.js-toggle--prev');
var galleryBtnNext 			=	$('.js-toggle--next');

var toggledGallery = false;
var toggledMenu = false;

main.init = function () {

	main.initEvents();
	main.setLayout();
	main.initLoadImages();

	if ( gridToRandomise.length != 0 ) {
		main.initRandomGrid();
	}

	$(window).on('popstate', function(e) {
	  main.loadLink(window.location.href, true);
	})

	$(window).on('load', function(){

		main.initHeader();

		FastClick.attach(document.body);

		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			touchdevice = true;
			$('html').addClass('is-touchdevice');
		}

	});

	$(window).on('resize', function(){
		main.setLayout();
	});

};

main.loadLink = function(url, nostatechange) {
  // this will load only the #main-content div on whatever page it requests
  // note the space in the quoted string below. URL should look like '/about #main-container'

	var loadUrl = url + " #main-content";
	var loadArea = $("#main");

	//Also load and replace gallery

	loadArea.load(loadUrl, function () {
    	if (!nostatechange) {
      		main.updateState(url);
      		main.updateMenu();
      		main.updateEvents();
      		main.setLayout();
			main.initLoadImages();
			main.scrollToTop();
      	};
    });

};

// updates the browser's URL and places a new item in the history
main.updateState = function(url) {

	var rootstring = window.location.origin;
	var newurl = url.replace(rootstring,'');
	var pageTitle = $('#main-content').attr('data-title');

	history.pushState({}, "page", newurl);
	document.title = 'Cleon Peterson, ' + pageTitle;

};

main.updateMenu = function() {
	var active = $('#main-content').attr('data-page');
	$('.js-nav__link').removeClass('active');
	$('.js-nav__link[data-page="' + active + '"]').addClass('active');
}

main.updateEvents = function() {

	$('.js-show-in-gallery').on('click', function(evt) {
		evt.preventDefault();
		var itemIndex = $(this).data('index');
		main.toggleGallery(itemIndex);
	});

	$('.c_gallery__btn--close').on('click', function(evt) {
		evt.preventDefault();
		main.toggleGallery(evt);
	});

	$('.js-toggle--prev').on('click', function(evt) {
		evt.preventDefault();
		main.toggleGalleryPrev(evt);
	});

	$('.js-toggle--next').on('click', function(evt) {
		evt.preventDefault();
		main.toggleGalleryNext(evt);
	});

	$('.js-toggle--prev').hover(
	  function() {
	    $('.c_gallery__btn--prev').addClass('is-visible');
	  }, function() {
	    $('.c_gallery__btn--prev').removeClass('is-visible');
	  }
	);

	$('.js-toggle--next').hover(
	  function() {
	    $('.c_gallery__btn--next').addClass('is-visible');
	  }, function() {
	    $('.c_gallery__btn--next').removeClass('is-visible');
	  }
	);

}

// this handles back & forward in the browser. It updates the URL and the history,
// so we tell our loadLink function to not update the state afterwards

main.initEvents = function() {

	$('body').on('contextmenu', 'img', function(e) {
	  return false;
	});

	navLink.on('click', function(e) {
		e.preventDefault();
		if (menu.hasClass('is-visible')) {
			main.toggleMenu();
		}
		var href = $(this).attr('href');
		main.loadLink(href)
	});

	btnToggleMenu.on('click', function(evt) {
		evt.preventDefault();
		main.toggleMenu();
	});

	itemToShowInGallery.on('click', function(evt) {
		evt.preventDefault();
		var itemIndex = $(this).data('index');
		main.toggleGallery(itemIndex);
	});

	galleryBtnClose.on('click', function(evt) {
		evt.preventDefault();
		main.toggleGallery(evt);
	});

	galleryBtnPrev.on('click', function(evt) {
		evt.preventDefault();
		main.toggleGalleryPrev(evt);
	});

	galleryBtnNext.on('click', function(evt) {
		evt.preventDefault();
		main.toggleGalleryNext(evt);
	});

	galleryBtnPrev.hover(
	  function() {
	    $('.c_gallery__btn--prev').addClass('is-visible');
	  }, function() {
	    $('.c_gallery__btn--prev').removeClass('is-visible');
	  }
	);

	galleryBtnNext.hover(
	  function() {
	    $('.c_gallery__btn--next').addClass('is-visible');
	  }, function() {
	    $('.c_gallery__btn--next').removeClass('is-visible');
	  }
	);

	$(document).keydown(function(evt){
	    if (evt.keyCode == 37) {
	       main.toggleGalleryPrev(evt);
	       return false;
	    }
	    if (evt.keyCode == 39) {
	       main.toggleGalleryNext(evt);
	       return false;
	    }
	});

};

main.initHeader = function() {

	//Show underlining
	$('.c-nav__list').addClass('show-active-state');

};

main.toggleMenu = function() {

	if ( toggledMenu ) {

		menu.toggleClass('is-visible');

	} else {

		menu.toggleClass('is-visible');

	}

	body.toggleClass('no-scroll');

	toggledMenu = !toggledMenu;


};

main.toggleGalleryPrev = function(evt) {

	evt.preventDefault();

	var count = $('.js-show-in-gallery').length;
	var current = $('.c_gallery__image-container figure.current');
	current.removeClass('current');

	if( current.index() === ( 0) ) {
		$('.c_gallery__image-container figure:last-child').addClass('current');
	} else {
		current.prev().addClass('current');
	}

};

main.toggleGalleryNext = function(evt) {

	evt.preventDefault();

	var count = $('.js-show-in-gallery').length;
	var current = $('.c_gallery__image-container figure.current');
	current.removeClass('current');


	if( current.index() === ( count -1) ) {
		$('.c_gallery__image-container figure:first-child').addClass('current');
	} else {
		current.next().addClass('current');
	}


};

main.toggleGallery = function(thumbIndex) {


	if ( toggledGallery ) {

		//Closing gallery
		$('.c_gallery__image-container figure').removeClass('current');
		setTimeout(function() {
			$('.c_gallery').toggleClass('is-visible');
		},220);
		console.log('closing gallery');

	} else {

		//Opening gallery
		$('.c_gallery').toggleClass('is-visible');
		setTimeout(function() {
			$('.c_gallery__image-container figure:eq('+ (thumbIndex-1) +')').addClass('current');
		},220);

		console.log('opening gallery');

	}

	body.toggleClass('no-scroll');

	toggledGallery = !toggledGallery;

};


main.initRandomGrid = function() {

	function Shuffle(o) {
		for(var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
		return o;
	};

	var placementOptions = ['u-float--left', 'u-float--center', 'u-float--right'];

	$('.u-float--random').each(function() {

		Shuffle(placementOptions);
		$(this).addClass(placementOptions[0]);

	});

};

main.initNewsletter = function(){

	function showResponse(resp) {
		$('.mc-email').val('Thank you for subscribing.');
	}

	$('#mc-form').ajaxChimp({
	    url: 'http://cleonpeterson.us6.list-manage.com/subscribe/post?u=566c2ba87b&amp;id=8e11a68783',
	    callback: showResponse
	});

};

main.loadImages = function(imageToLoad) {

	if( imageToLoad ) {
		body.imagesLoaded().progress( function(instance,image){
			$(imageToLoad).addClass('is-loaded');
		});
	}

};

main.initLoadImages = function() {

	var imageToLoad = $('.o-image');

	imageToLoad.each(function() {
		var tb = $(this);
	}).promise().done(main.loadImages(imageToLoad));

};

main.scrollToTop = function() {

	$('html,body').animate({
        scrollTop: 0
    }, 440);

}

main.setLayout = function() {

	ww = $(window).width();
	wh = $(window).height();

	//Calculate height by dividing window height by amount of sections
	var sectionNumber = $('.js-calc-vh > section').length;
	$('.js-calc-vh > section').css('height',100/4 + 'vh');

};

$(main.init);
